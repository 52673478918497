import {useEffect} from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Button, Input} from '../index';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {quantitySchema} from '../../../app/schema/admin.schema';
import {QuantityContent} from '../../../app/server/types/admin-control.types';

export type QuantityInputProps = {
  label?: string
  quantity?: QuantityContent;
  isLoading?: boolean;
  error?: string
  min?: number
  onSubmit?: (value: QuantityContent) => void;
};

const QuantityInput = ({
  label,
  quantity,
  onSubmit,
  isLoading,
  error,
  min
}: QuantityInputProps) => {
  const {
    control,
    getValues,
    reset,
    formState: {errors},
    handleSubmit,
  } = useForm<QuantityContent>({
    resolver: yupResolver(quantitySchema),
    defaultValues: {
      value: 0,
    },
  });

  useEffect(() => {
    if (quantity) {
      reset(quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const submitForm = () => {
    handleSubmit(submit)();
  };

  const submit = () => {
    if (onSubmit) {
      onSubmit(getValues());
    }
  };

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row space-x-3">
        <div style={{minWidth: '100px'}}>
          <Controller
            control={control}
            name="value"
            render={({field: {value, onBlur, onChange}}) => (
              <Input
                label={label ? label : "Frequency"}
                type="number"
                inputSize="large"
                placeholder="Enter value"
                onChange={onChange}
                min={min}
                value={
                  typeof value === 'number'
                    ? value
                    : isNaN(parseFloat(value as string))
                    ? ''
                    : parseFloat(value as string)
                }
                error={errors.value?.message || error}
                onBlur={onBlur}
              />
            )}
          />
        </div>
      </div>
      <div>
        <Button
          label="Save"
          color="primary"
          onClick={submitForm}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default QuantityInput;
