import React, {createRef, useState} from 'react';
import {StyledDialog} from './styled';
import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';

interface ReadRequiredDocumentDialogProps {
  headerText: string;
  content: string;
  disagreeButtonText: string;
  agreeButtonText: string;
  onDisagreeButtonAction: () => void;
  onAgreeButtonAction: () => void;
  accepted?: boolean;
}

const ReadRequiredDocumentDialog = (props: ReadRequiredDocumentDialogProps) => {
  const [isAgreeAllowed, setIsAgreeAllowed] = useState<boolean>(
    props.accepted ? props.accepted : false,
  );
  const messageContainerRef = createRef<HTMLDivElement>();
  const content = createRef<HTMLDivElement>();

  const onScroll = () => {
    if (messageContainerRef.current) {
      const total =
        messageContainerRef.current.scrollHeight -
        messageContainerRef.current.offsetHeight;
      const current = messageContainerRef.current.scrollTop / total;
      if (current > 0.9) {
        setIsAgreeAllowed(true);
      } 
    }
  };

  const onAgreeClick = () => {
    if (!isAgreeAllowed) {
      content.current?.scrollIntoView({ behavior: 'smooth' });
      setIsAgreeAllowed(true);
    } else {
      props.onAgreeButtonAction();
    }
  }

  return (
    <StyledDialog className="flex flex-col items-center">
      <div className="header">
        <Typography
          label={props.headerText}
          variant="f3"
          weight="semibold"
          color={colorTheme.dark}
        />
      </div>

      <div ref={messageContainerRef} className="message" onScroll={onScroll}>
        <div className="tac">
          <div dangerouslySetInnerHTML={{__html: props.content}} />
        </div>
        <div ref={content}></div>
      </div>

      <div className="pt-2 flex w-full justify-start">
        <Typography
          label="Scroll to the end to continue."
          variant="f1"
          color={colorTheme.dark}
        />
      </div>

      <div className="actions flex flex-row w-full">
        <Button
          label={props.disagreeButtonText}
          variant="outlined"
          className="flex-1"
          onClick={props.onDisagreeButtonAction}
        />
        <div className="spacer" />
        <Button
          label={props.agreeButtonText}
          color={isAgreeAllowed ? 'primary' : 'darkFaded'}
          className="flex-1"
          onClick={onAgreeClick}  
        />
      </div>
    </StyledDialog>
  );
};

export default ReadRequiredDocumentDialog;
