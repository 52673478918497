import React, {FormEventHandler} from 'react';
import {
  StyledInputIcon,
  StyleInput,
  StyleInputProps,
  StyledInputLabel,
  StyledNumberFormat,
} from './styled';
import {colorTheme} from '../../configs';
import {InputSize, InputType} from './input.types';
import NumberFormat from 'react-number-format';
import VerifiedTag from '../verified-tag/VerifiedTag';

export type InputProps = {
  label?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  placeholder?: string;
  value?: React.ReactText;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  type?: InputType;
  inputSize?: InputSize;
  maxLength?: number;
  min?: number;
  error?: string;
  showThousandSeparator?: boolean;
  numericOnly?: boolean;
  onChange?: FormEventHandler<HTMLInputElement>;
  onBlur?: FormEventHandler<HTMLInputElement>;
  onFocus?: FormEventHandler<HTMLInputElement>;
  onSubmit?: FormEventHandler<HTMLInputElement>;
  onClick?: () => void;
  ref?: any;
  verified?: boolean;
};

const Input: React.FC<InputProps> = props => {
  const {
    label,
    iconLeft,
    iconRight,
    placeholder,
    value,
    disabled = false,
    className,
    error,
    maxLength,
    min,
    type = 'text',
    inputSize = 'default',
    showThousandSeparator = false,
    readOnly = false,
    numericOnly = false,
    verified = false,
    onChange,
    onBlur,
    onFocus,
    onSubmit,
    onClick,
  } = props;

  const styles: StyleInputProps = {
    inputSize,
    error,
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (numericOnly && ['e', 'E', '-', '.'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyPress = (e: any) => {
    if (onSubmit && e.key === 'Enter') {
      onSubmit(e);
    }
  };

  return (
    <div>
      {label && (
        <div className="flex space-x-1 items-center mb-1">
          <StyledInputLabel data-testid="label-element" {...styles}>
            {label}
          </StyledInputLabel>
          {verified && <VerifiedTag />}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          position: 'relative',
        }}
        onClick={onClick}>
        {iconLeft && (
          <StyledInputIcon
            {...styles}
            iconLeft={true}
            data-testid="icon-left-element">
            {iconLeft}
          </StyledInputIcon>
        )}

        {showThousandSeparator ? (
          <StyledNumberFormat {...styles}>
            <NumberFormat
              data-testid="input-element"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyDown={numericOnly ? handleKeyDown : undefined}
              onKeyPress={handleKeyPress}
              placeholder={placeholder}
              className={className}
              disabled={disabled}
              maxLength={maxLength}
              thousandSeparator
            />
          </StyledNumberFormat>
        ) : (
          <StyleInput
            readOnly={readOnly}
            data-testid="input-element"
            type={type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={numericOnly ? handleKeyDown : undefined}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
            className={className}
            iconLeft={!!iconLeft}
            iconRight={!!iconRight}
            disabled={disabled}
            maxLength={maxLength}
            min={min}
            {...styles}
          />
        )}

        {iconRight && (
          <StyledInputIcon
            {...styles}
            iconRight={true}
            data-testid="icon-right-element">
            {iconRight}
          </StyledInputIcon>
        )}
      </div>

      {error && (
        <div
          data-testid="error-msg-element"
          style={{
            marginTop: '0.25rem',
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            color: colorTheme.danger,
          }}>
          {error}
        </div>
      )}
    </div>
  );
};

export default Input;
