import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {Button, Icon, TextArea, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import {StyledDisclaimer, StyledRiskyWarning, StyledWrapper} from './styled';
import {chatSchema} from '../../../schema/chat.schema';
import {useToast} from '../../../../core/components/toast';
import {localization} from '../../../localization/Localization';
import { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { isUsingApp } from '../../../utils/random.util';
import { InAppBrowser } from '@capgo/inappbrowser';

export type ChatDisabledType = 'recipientIsBanned' 
  | 'senderIsSuspended'
  | 'recipientIsDeleted'
  | 'recipientIsDisabled'
  | 'conversationWithAdminEnded'
  | 'recipientIsHired'
  | 'senderIsHired';

interface Chat {
  message: string;
}

interface ChatTextProps {
  recipientName?: string
  isMenuOptionsOpen?: boolean;
  isAddAttachmentTriggered?: boolean;
  isSubscribed?: boolean
  unSubscribedMessage?: string | null
  disabledType?: ChatDisabledType | null
  jobStartDate?: Date | null
  resumeChatDate?: Date | null
  risky?: boolean
  onSendMessage: (message: string) => void;
  onSelectMedia: (file: any | null) => void;
  setUploadedUri: (uri: string | undefined) => void;
  onClickSubscribe?: () => void;
  onClickOpenOptions?: () => void;
  
}

const   ChatText = ({
  onSendMessage,
  onSelectMedia,
  setUploadedUri,
  onClickSubscribe,
  isSubscribed = false,
  isMenuOptionsOpen = false,
  isAddAttachmentTriggered = false,
  unSubscribedMessage,
  disabledType = null,
  jobStartDate,
  resumeChatDate,
  recipientName,
  risky = false,
  onClickOpenOptions,

}: ChatTextProps) => {  
  let inputElement: HTMLElement | null;
  const {presentToast} = useToast();
  const [hideWarning, setHideWarning] = useState<boolean>(false);

  const handleClick = () => {
    // inputElement?.click();
    onClickOpenOptions?.();
  };

  useEffect(()=>{
    if(isAddAttachmentTriggered){
      inputElement?.click();
    }
  }, [isAddAttachmentTriggered])

  const fileSizeChecker = (fileSize: number, uri: string, file: Blob) => {
    if (fileSize / 1000000 > 5) {
      presentToast({
        message: 'File size is over 5MB',
        variant: 'error',
        position: 'bottom',
      });
    } else if (fileSize && fileSize / 1000000 <= 5) {
      onSelectMedia(file);
      setUploadedUri(uri);

      onClickOpenOptions?.();
    }
  };

  const {control, getValues, reset, handleSubmit} = useForm<Chat>({
    resolver: yupResolver(chatSchema),
    defaultValues: {
      message: '',
    },
  });

  const submitForm = async () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async () => {
    var data = getValues();
    onSendMessage(data.message);
    reset();
  };

  const handleFileChange = (event: any) => {
    const {target} = event;
    const {files} = target;

    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = () => {
        const fileSize = files[0].size;
        fileSizeChecker(fileSize, URL.createObjectURL(files[0]), files[0]);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const getMessage = () => {
    switch (disabledType) {
      case 'conversationWithAdminEnded':
        return "You can't reply to this conversation.";
      case 'senderIsSuspended':
        return "You have been suspended. You can't reply to this conversation.";
      case 'recipientIsBanned':
        return "This person is banned on kazam.";
      case 'recipientIsHired':
        return `${recipientName} has already accepted a job offer on ${format(jobStartDate ?? new Date(), 'PP')}. \
          This chat is temporarily locked to protect ${recipientName}, the hiring homeowner, \
          and yourself. You may resume chatting on ${format(resumeChatDate ?? new Date(), 'PP')}.`
      case 'senderIsHired':
        return `As you have already accepted a job offer from another homeowner on \
        ${format(jobStartDate ?? new Date(), 'PP')}, this chat is temporarily locked to protect other homeowners, \
        the hiring homeowner, and yourself. You may resume chatting on ${format(resumeChatDate ?? new Date(), 'PP')}.`
    
      default:
        return "This person is unavailable on kazam.";
    }


    // if (conversationEnded) {
    //   return "You can't reply to this conversation." 
    // } else {
    //   if (senderIsSuspended) return "You have been suspended. You can't reply to this conversation." 
    //   if (isBanned) return "This person is banned on kazam." 
    //   return "This person is unavailable on kazam."
    // }
  }

  const openLearnMore = async () => {
    var url = "https://news.kazam.ph/protect-yourself-from-scams/";
    if (isUsingApp()) {
      await InAppBrowser.openWebView({
        url: url,
        toolbarColor: '#ffffff',
        closeModal: true,
      });
    } else {
      window.open(url, '_blank');
    }
  }

  return (
    <StyledWrapper className='chat-input-wrapper'>
      {!!!disabledType
      && isSubscribed
      && risky
      && !hideWarning && (
        <StyledRiskyWarning>
          <div className='flex justify-between items-center'>
            <div className='flex space-x-2 md:space-x-4 items-center'>
              <div>
                <Icon
                  name="alertTriangle"
                  size={20  }
                  color={colorTheme.warning}
                />
              </div>
              
              <div className='flex flex-col'>
                <Typography
                  className='overflow'
                  label="This could be a scam"
                  variant="f1"
                  weight="semibold"
                  color={colorTheme.dark}
                  align="left"
                />

                <div className='flex flex-wrap description space-x-1'>
                  Learn what you can do if you see something suspicious.
                  <div className="learn-more cursor-pointer" onClick={openLearnMore}> Learn more</div>
                </div>
              </div>
            </div>
            
            <div>
              <Icon
                name="x"  
                size={24}
                color={colorTheme.darkFaded}
                type="button"
                onClick={() => setHideWarning(!hideWarning)}
              />
            </div>
          </div>
        </StyledRiskyWarning>
      )}

      {!!!disabledType && isSubscribed && (
        <StyledDisclaimer>
          <Typography
            className="overflow"
            label={localization.desc_disclaimer}
            variant="caption"
            color={colorTheme.white}
            align="center"
          />
        </StyledDisclaimer>
      )}

      {isSubscribed ? (
        !!!disabledType ? (
          <div className="flex flex-row items-center space-x-4">
            {/* <Icon
              className="cursor-pointer"
              name="paperclip"
              color={colorTheme.darkFaded}
              type="button"
              size={24}
              onClick={handleClick}
            /> */}
            {(isMenuOptionsOpen ? (
              <Icon
                className="cursor-pointer"
                name="crossLight"
                color={colorTheme.darkFaded}
                type="button"
                size={24}
                onClick={handleClick}
              />
            ) : (
              <Icon
                className="cursor-pointer"
                name="plusBig"
                color={colorTheme.dark}
                type="button"
                size={24}
                onClick={handleClick}
              />
            ))}
            
  
            <Controller
              control={control}
              name="message"
              render={({field: {value = '', onBlur, onChange}}) => (
                <TextArea
                  placeholder={localization.ph_write_msg}
                  rows={1}
                  maxRows={3}
                  autoresize
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                />
              )}
            />
  
            <Icon
              className="cursor-pointer"
              name="send"
              color={colorTheme.darkFaded}
              type="button"
              size={24}
              onClick={submitForm}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <Typography
              label={getMessage()}
              variant="f1"
              color={colorTheme.darkFaded}
              align="center"
            />
          </div>
        )
      ) : (
        <div className="flex items-center justify-center flex-col space-y-2">
          <Typography
            label={unSubscribedMessage
              ? unSubscribedMessage 
              : "You do not have any active subscription. To send messages, click link below to subscribe."}
            variant="f1"
            color={colorTheme.darkFaded}
            align="center"
          />

          <div style={{maxWidth: '200px'}}>
            <Button
              label="Subscribe to chat"
              color='green'
              variant='contained'
              onClick={onClickSubscribe}
            />
          </div>
        </div>
      )}

      <input
        type="file"
        ref={input => (inputElement = input)}
        accept="image/jpeg, image/png, .svg, image/heic, image/heic-sequence, .pdf"
        onChange={handleFileChange}
        style={{display: 'none'}}
      />
    </StyledWrapper>
  );
};

export default ChatText;
