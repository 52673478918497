import {Button, Typography} from '../../../../core/components';
import {colorTheme} from '../../../../core/configs';
import searchIllustration from '../../../../assets/img_I_Am_Homeowner.svg';
import {StyledImg} from './styles';
import {localization} from '../../../localization/Localization';
import { MatchType } from '../../../server/types/match.types';
import { useGetAdminControl } from '../../../server/react-query';
import { useAuth } from '../../../hooks/useAuth';
import { QuantityContent } from '../../../server/types/admin-control.types';
import { toNumberWithCommas } from '../../../utils/decimal.util';

interface DialogProps {
  matchType?: MatchType;
  onContinueClick: () => void;
}

const TierResultDialogContentView = (props: DialogProps) => {
  const {authData} = useAuth();
  const {data: multiplier} = useGetAdminControl('salaryThresholdMultiplier');
  
  const getHeader = (): string => {
    switch (props.matchType) {
      case 'tier2':
        var min = authData?.user?.filterSetting?.salary ?? 0;
        var max = min
        if (multiplier
          && multiplier.enabled
          && multiplier.content) {
          let data = JSON.parse(multiplier.content)
          max = data.value * min;
        }

        return `We have more matches in the range ₱ ${toNumberWithCommas(min)} - ₱ ${toNumberWithCommas(max)}. Do you want to show these candidates?`
      case 'tier3':
        return "Do you wish to see matches that were active more than a month ago? Chat with them and an SMS notification will be sent to their mobile."
      default:
        return "";
    }
  }
  return (
    <div className="flex flex-col items-center p-4 space-y-4">
      <StyledImg src={searchIllustration} alt="Kaway"></StyledImg>
      <div className="space-y-2">
        <Typography
          label={getHeader()}
          align="center"
          weight="semibold"
          variant="f3"
          color={colorTheme.dark}
        />
        <div className="flex justify-center space-x-1">
          <div style={{width: '175px'}}>
            <Typography
              align="center"
              label="Click Continue to proceed."
              weight="normal"
              variant="f1"
              color={colorTheme.darkFaded}
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        <Button
          label={localization.btn_continue}
          color="primary"
          onClick={props.onContinueClick}
        />
      </div>
    </div>
  );
};

export default TierResultDialogContentView;
