import {useMutation, useQuery, useQueryClient} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';
import {
  GetAbuseReportsResponse,
  PostBanUnbanUserBody,
  GetBannedUsersResponse,
  GetUsersResponse,
  GetAbuseReportersResponse,
  VerifiedStatus,
  DeleteDocumentBody,
  GetHighRiskUsersResponse,
  GetHighRiskUserResponse,
} from '../types';
import {
  PaginatedUsersQueryParams,
  PatchRevokeVerificationStatusBody,
  PatchUserVerificationStatusBody,
} from '../types/admin-control.types';
import {DeleteDocumentResponse} from '../types/user.verification.types';

const postBanUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_BAN_USER, reqBody);
};

const usePostBanUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-ban-user'],
    (reqBody: PostBanUnbanUserBody) => postBanUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-banned-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
      },
    },
  );
};

const postUnbanUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_UNBAN_USER, reqBody);
};

const usePostUnbanUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-unban-user'],
    (reqBody: PostBanUnbanUserBody) => postUnbanUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-banned-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
      },
    },
  );
};

const getBannedUsers = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_BANNED_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetBannedUsersResponse>(url);
};

const useGetBannedUsers = (pageNumber: number, searchQuery: string) => {
  return useQuery(['get-banned-users', pageNumber], () =>
    getBannedUsers(pageNumber, searchQuery),
  );
};

const getUsers = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetUsersResponse>(url);
};

const useGetUsers = (pageNumber: number, searchQuery: string) => {
  return useQuery(['get-users', pageNumber], () =>
    getUsers(pageNumber, searchQuery),
  );
};

const getAbuseReports = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_ABUSE_REPORT +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetAbuseReportsResponse>(url);
};

const useGetAbuseReports = (pageNumber: number, searchQuery: string) => {
  return useQuery(['get-abuse-reports', pageNumber], () =>
    getAbuseReports(pageNumber, searchQuery),
  );
};

const getAbuseReporters = (userId: string, pageNumber: number) => {
  const url =
    ApiEndpointsEnum.GET_ABUSE_REPORTERS.replace('{userId}', userId) +
    `?page=${pageNumber}`;
  return API.get<GetAbuseReportersResponse>(url);
};

const useGetAbuseReporters = (userId: string, pageNumber: number) => {
  return useQuery(['get-abuse-reporters', userId, pageNumber], () =>
    getAbuseReporters(userId, pageNumber),
  );
};

const postSuspendUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_SUSPEND_USER, reqBody);
};

const usePostSuspendUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-suspend-user'],
    (reqBody: PostBanUnbanUserBody) => postSuspendUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
      },
    },
  );
};

const postUnsuspendUser = (reqBody: PostBanUnbanUserBody) => {
  return API.post(ApiEndpointsEnum.POST_UNSUSPEND_USER, reqBody);
};

const usePostUnsuspendUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-unsuspend-user'],
    (reqBody: PostBanUnbanUserBody) => postUnsuspendUser(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
      },
    },
  );
};

const getSuspendedUsers = (pageNumber: number, searchQuery: string) => {
  const url =
    ApiEndpointsEnum.GET_SUSPENDED_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetBannedUsersResponse>(url);
};

const deleteDocumentAsAdmin = (userId: string) => {
  const payload = {
    data: userId,
  };

  return API.del<DeleteDocumentResponse>(
    ApiEndpointsEnum.DELETE_DOCUMENT_AS_ADMIN,
    payload,
  );
};

const useDeleteDocumentAsAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['delete-document-as-admin'],
    (userId: string) => deleteDocumentAsAdmin(userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['delete-document-as-admin']);
      },
    },
  );
};

const patchManualVerification = (reqBody: PatchUserVerificationStatusBody) => {
  return API.patch(ApiEndpointsEnum.PATCH_MANUAL_VERIFICATION, reqBody);
};

const usePatchManualVerification = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-manual-verification'],
    (reqBody: PatchUserVerificationStatusBody) =>
      patchManualVerification(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
        await queryClient.invalidateQueries([`get-for-verification-users`]);
        await queryClient.invalidateQueries([`get-verified-users`]);
      },
    },
  );
};

const patchRevokeManualVerification = (
  reqBody: PatchUserVerificationStatusBody,
) => {
  return API.patch(ApiEndpointsEnum.PATCH_REVOKE_MANUAL_VERIFICATION, reqBody);
};

const usePatchRevokeManualVerification = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-revoke-manual-verification'],
    (reqBody: PatchUserVerificationStatusBody) =>
      patchRevokeManualVerification(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
        await queryClient.invalidateQueries([`get-for-verification-users`]);
        await queryClient.invalidateQueries([`get-verified-users`]);
      },
    },
  );
};

const patchRevokeVerifications = (
  reqBody: PatchUserVerificationStatusBody
) => {
  return API.patch(ApiEndpointsEnum.PATCH_REVOKE_VERIFICATIONS, reqBody);
}

const usePatchRevokeVerifications = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-revoke-verifications'],
    (reqBody: PatchUserVerificationStatusBody) =>
      patchRevokeVerifications(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
        await queryClient.invalidateQueries([`get-for-verification-users`]);
        await queryClient.invalidateQueries([`get-verified-users`]);
      },
    },
  );
};

const patchRevokeEkycVerifications = (
  reqBody: PatchRevokeVerificationStatusBody
) => {
  return API.patch(ApiEndpointsEnum.PATCH_REVOKE_EKYC_VERIFICATIONS, reqBody);
}

const usePatchRevokeEkycVerifications = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patch-revoke-ekyc-verifications'],
    (reqBody: PatchRevokeVerificationStatusBody) =>
      patchRevokeEkycVerifications(reqBody),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('get-suspended-users');
        await queryClient.invalidateQueries(['get-abuse-reports']);
        await queryClient.invalidateQueries(['get-users']);
        await queryClient.invalidateQueries([`get-for-verification-users`]);
        await queryClient.invalidateQueries([`get-verified-users`]);
      },
    },
  );
};


const useGetSuspendedUsers = (pageNumber: number, searchQuery: string) => {
  return useQuery(['get-suspended-users', pageNumber], () =>
    getSuspendedUsers(pageNumber, searchQuery),
  );
};

const getUsersWithEkycVerifiedStatus = (
  pageNumber: number,
  searchQuery: string,
) => {
  const url =
    ApiEndpointsEnum.GET_USERS_WITH_EKTC_VERIFIED_STATUS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetUsersResponse>(url);
};

const useGetEkycVerifiedUsers = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-ekyc-verified-users', params.page], () =>
    getUsersWithEkycVerifiedStatus(params.page, params.searchQuery ?? ''),
  );
};

const getUsersWithVerifiedStatus = (
  status: VerifiedStatus,
  pageNumber: number,
  searchQuery: string,
) => {
  const url =
    ApiEndpointsEnum.GET_USERS_WITH_VERIFIED_STATUS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '') +
    (status ? `&status=${status}` : '');
  return API.get<GetUsersResponse>(url);
};

const useGetForVerificationUsers = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-for-verification-users', params.page], () =>
    getUsersWithVerifiedStatus(
      'pending',
      params.page,
      params.searchQuery ?? '',
    ),
  );
};

const useGetVerifiedUsers = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-verified-users', params.page], () =>
    getUsersWithVerifiedStatus(
      'verified',
      params.page,
      params.searchQuery ?? '',
    ),
  );
};

const getHighRiskUsers = (
  pageNumber: number,
  searchQuery: string,
) => {
  const url =
    ApiEndpointsEnum.GET_HIGH_RISK_USERS +
    `?page=${pageNumber}` +
    (searchQuery.trim() ? `&searchQuery=${searchQuery}` : '');
  return API.get<GetHighRiskUsersResponse>(url);
};

const useGetHighRiskUsers = (params: PaginatedUsersQueryParams) => {
  return useQuery(['get-high-risk-users', params.page], () =>
    getHighRiskUsers(
      params.page,
      params.searchQuery ?? '',
    ),
  );
};

const getHighRiskUser = (userId: string, pageNumber: number) => {
  const url =
    ApiEndpointsEnum.GET_HIGH_RISK_USER.replace('{id}', userId) +
    `?page=${pageNumber}`;
  return API.get<GetHighRiskUserResponse>(url);
};

const useGetHighRiskUser = (userId: string, pageNumber: number) => {
  return useQuery(['get-high-risk-user', userId, pageNumber], () =>
    getHighRiskUser(userId, pageNumber),
  );
};

const putFlagConversation = (channelId: string) => {
  const url = ApiEndpointsEnum.PUT_FLAG_CONVERSATION.replace('{id}', channelId);
  return API.put(url);
};

const usePutFlagConversation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-flag-conversation'],
    (channelId: string) => putFlagConversation(channelId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['get-high-risk-user']);
      },
    },
  );
};

const putUnflagConversation = (channelId: string) => {
  const url = ApiEndpointsEnum.PUT_UNFLAG_CONVERSATION.replace('{id}', channelId);
  return API.put(url);
};

const usePutUnflagConversation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['put-unflag-conversation'],
    (channelId: string) => putUnflagConversation(channelId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['get-high-risk-user']);
      },
    },
  );
};

export {
  useGetBannedUsers,
  useGetUsers,
  useGetAbuseReports,
  useGetAbuseReporters,
  usePostBanUser,
  usePostUnbanUser,
  usePostSuspendUser,
  usePostUnsuspendUser,
  useGetSuspendedUsers,
  useGetForVerificationUsers,
  useGetVerifiedUsers,
  useGetEkycVerifiedUsers,
  useDeleteDocumentAsAdmin,
  usePatchManualVerification,
  usePatchRevokeManualVerification,
  usePatchRevokeVerifications,
  usePatchRevokeEkycVerifications,
  useGetHighRiskUsers,
  useGetHighRiskUser,
  usePutFlagConversation,
  usePutUnflagConversation
};
