import React, {useEffect, useReducer, useState} from 'react';
import {
  useGetBannedUsers,
  useGetSuspendedUsers,
  useGetSystemStatistics,
  usePostBanUser,
  usePostSuspendUser,
  usePostUnbanUser,
  usePostUnsuspendUser,
} from '../../../../server/react-query';
import AdminTable from '../AdminTable';
import {StyledAdminUsersTable} from '../styled';
import {useToast} from '../../../../../core/components/toast';
import {Column} from 'react-table';
import {Icon, Typography} from '../../../../../core/components';
import {colorTheme} from '../../../../../core/configs';
import {useAnalytics} from '../../../../hooks/useAnalytics';
import {useAuth} from '../../../../hooks/useAuth';
import { reducer, INITIAL_SEARCH_STATE } from '../verified/AdminVerifiedUsersTable';
import { useGetHighRiskUsers } from '../../../../server/react-query/useAdminUsers';
import { RoutePath } from '../../../../navigation/config/RouteConfig';
import { useHistory } from 'react-router-dom';

interface TableData {
  userId: string;
  name: string;
  location: string;
  userType: string;
  verificationStatus: string;
  numberOfHighRiskChats: number;
  isBanned: boolean;
  isSuspended: boolean;
}

interface AdminHighRiskUsersTableProps {
  searchQuery: string;
}

const AdminHighRiskUsersTable = (props: AdminHighRiskUsersTableProps) => {
  const {searchQuery: propSearchQuery} = props;

  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [data, setData] = useState<TableData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>(propSearchQuery);
  const [searchBody, dispatchSearch] = useReducer(reducer, INITIAL_SEARCH_STATE)
  const {
    data: hookDataHighRiskUsers,
    refetch: refetchGetHighRiskUsers,
  } = useGetHighRiskUsers(searchBody);
  const {
    data: hookDataSystemStatistics,
    refetch: refetchSystemStatistics,
  } = useGetSystemStatistics();
  const {systemLogEvent} = useAnalytics();
  const {authData} = useAuth();

  const {presentToast} = useToast();

  const history = useHistory();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'User ID',
      accessor: 'userId',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'No. of High Risk Chats',
      accessor: 'numberOfHighRiskChats',
    },
    {
      Header: 'Verification Status',
      accessor: 'verificationStatus',
    },
    {
      Header: 'User Type',
      accessor: 'userType',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
    {
      Header: '',
      accessor: 'open',
    },
  ] as Column<TableData>[]);

  useEffect(() => {
    refetchGetHighRiskUsers()
  }, [searchBody]);

  useEffect(() => {
    setPageNumber(1)
    setSearchQuery(propSearchQuery);
  }, [propSearchQuery]);

  useEffect(() => {
    dispatchSearch({
      type: 'SET_SEARCH',
      payload: {
        search: searchQuery?.trim(),
      },
    });
  }, [searchQuery]);

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
    dispatchSearch({
      type: 'SET_PAGE',
      payload: {
        page: pageNumber
      },
    });
  };

  useEffect(() => {
    if (hookDataHighRiskUsers) {
      setPageCount(
        Math.ceil(hookDataHighRiskUsers.total / hookDataHighRiskUsers.pageSize),
      );
      setData(
        hookDataHighRiskUsers.data.map(i => {
          const userId = i.userId;
          const name = `${i.user.firstName} ${i.user.lastName}`;

          let location = '';
          if (i.user.filterSetting) {
            const userLocation = i.user.filterSetting.location;
            if (userLocation) {
              location = userLocation?.cityOrMunicipality;
              if (userLocation.hasProvince) {
                location += `, ${userLocation.province.name}`;
              }
            }
          }

          const numberOfHighRiskChats = i.numberOfHighRiskConversations;
          const userType = i.user.userRole === 'homeowner' ? 'Homeowner' : 'Kasambahay';
          const isVerified = i.user.verifiedStatus == 'verified' || i.user.faceDocumentVerified;
          const verificationStatus = isVerified ? "Verified" : "Not verified";
          const isBanned = i.user.isBanned;
          const isSuspended = i.user.userStatus === 'suspended';
          return {
            userId,
            name,
            location,
            numberOfHighRiskChats,
            verificationStatus,
            userType,
            isBanned,
            isSuspended
          } as TableData;
        }),
      );
    }
  }, [hookDataHighRiskUsers]);

  const postBanUser = usePostBanUser();
  const postUnbanUser = usePostUnbanUser();
  const onBanUserClickedHandler = async (
    rowData: TableData
  ) => {
    try {
      if (rowData.isBanned)
        await postUnbanUser.mutateAsync({userId: rowData.userId});
      else
        await postBanUser.mutateAsync({userId: rowData.userId})

      presentToast({
        message: `${rowData.isBanned ? 'Unbanned' : 'Banned'} successfully.`,
        variant: 'success',
        position: 'bottom',
      });

    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const postSuspendUser = usePostSuspendUser();
  const postUnsuspendUser = usePostUnsuspendUser();
  const onSuspendUserClickedHandler = async (rowData: TableData) => {
    try {
      if (rowData.isSuspended)
        await postUnsuspendUser.mutateAsync({userId: rowData.userId})
      else
        await postSuspendUser.mutateAsync({userId: rowData.userId});

      presentToast({
        message: `${rowData.isSuspended ? 'Unsuspended' : 'Suspended'} successfully.`,
        variant: 'success',
        position: 'bottom',
      });

    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    if (columnId === 'open') {
      return (
        <div className="flex flex-col justify-center items-end">
          <Icon name="chevronRight" color={colorTheme.dark} />
        </div>
      );
    }
    
    return (
      <div className='flex space-x-2'>
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onBanUserClickedHandler(rowData);
          }}>
          <Typography
            label={rowData.isBanned ? 'Unban' : 'Ban'}
            variant="f2"
            color={colorTheme.primary}
            align="left"
          />
        </div>

        <div>|</div>

        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onSuspendUserClickedHandler(rowData);
          }}>
          <Typography
            label={rowData.isSuspended ? 'Unsuspend' : 'Suspend'}
            variant="f2"
            color={colorTheme.primary}
            align="left"
          />
        </div>
      </div>
    );
  };

  const tableRowClickHandler = (rowData: TableData) => {
    let url = RoutePath.ADMIN_HIGH_RISK_USER.toString();
    url = url.replace(':id', rowData.userId);
    history.push(url);
  };

  return (
    <StyledAdminUsersTable>
      <AdminTable
        columns={columns}
        data={data}
        pageSize={10}
        pageNumber={pageNumber}
        pageCount={pageCount}
        showDebugView={false}
        onRowClick={tableRowClickHandler}
        onPageChange={onPageChangedHandler}
        manualRenderFn={manualRenderFn}
      />
    </StyledAdminUsersTable>
  );
};

export default AdminHighRiskUsersTable;
