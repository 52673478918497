import React, {useEffect, useState} from "react";
import AdminPage from "../../../../../core/components/admin-page/AdminPage";
import {Button, Icon, Typography} from "../../../../../core/components";
import {colorTheme} from "../../../../../core/configs";
import AdminTable from "../AdminTable";
import {StyledAdminReportersViewPage} from "../styled";
import {UserResponse} from "../../../../server/types";
import {useHistory, useParams} from "react-router-dom";
import {useGetUserById, usePostJob} from "../../../../server/react-query";
import {format} from "date-fns";
import {Column} from "react-table";
import ExtractReportDialog from "../../../../views/dialog-content/ExtractReportDialog";
import {useToast} from "../../../../../core/components/toast";
import {useDialog} from "../../../../../core/components/dialog";
import {useAuth} from "../../../../hooks/useAuth";
import { RoutePath } from "../../../../navigation/config/RouteConfig";
import { useGetHighRiskUser, usePutFlagConversation, usePutUnflagConversation } from "../../../../server/react-query/useAdminUsers";

interface AdminHighRiskUserViewPageParams {
  id: string;
}

interface TableData {
  userId: string;
  name: string;
  location: string;
  conversationId: string;
  numberOfHighRiskChats: number;
  isFlagged: boolean
}

const AdminHighRiskUserViewPage = () => {
  const [user, setUser] = useState<UserResponse | undefined>(undefined);
  const [data, setData] = useState<TableData[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const history = useHistory();
  const {id: userId} = useParams<AdminHighRiskUserViewPageParams>();

  const {data: hookDataUser} = useGetUserById(userId);

  const {data: hookDataGetHighRiskUser, refetch} = useGetHighRiskUser(userId, pageNumber);

  const postJob = usePostJob();
  const {presentToast} = useToast();
  const {presentDialog, dismissDialog} = useDialog();
  const {authData} = useAuth();

  const [columns] = useState<Column<TableData>[]>([
    {
      Header: 'Name',
      accessor: 'name', // accessor is the "key" in the data
    },
    {
      Header: 'User ID',
      accessor: 'userId', // accessor is the "key" in the data
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Conversation ID',
      accessor: 'conversationId',
    },
    {
      Header: 'Predicted Instances',
      accessor: 'numberOfHighRiskChats',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
    {
      Header: '',
      accessor: 'open',
    },
  ] as Column<TableData>[]);

  useEffect(() => {
    (async () => {
      await refetch();
    })();
  }, [pageNumber]);

  useEffect(() => {
    if (hookDataGetHighRiskUser) {
      const response = hookDataGetHighRiskUser;
      setPageCount(Math.ceil(response.total / response.pageSize));
      setData(response.data.map(i => {
        const userId = i.userId;

        const name = `${i.user.firstName} ${i.user.lastName}`;

        let location = '';
        const userLocation = i.user.filterSetting.location;
        if (userLocation) {
          location = userLocation?.cityOrMunicipality;
          if (userLocation.hasProvince) {
            location += `, ${userLocation.province.name}`;
          }
        }

        const conversationId = i.channelId;
        const numberOfHighRiskChats = i.numberOfHighRiskChats;
        const isFlagged = i.risky;

        return {userId, name, location, conversationId, numberOfHighRiskChats, isFlagged} as TableData;
      }));
    }
  }, [hookDataGetHighRiskUser]);

  useEffect(() => {
    if (hookDataUser) {
      setUser(hookDataUser);
    }
  }, [hookDataUser]);

  const onBackButtonClickedHandler = () => {
    history.go(-1);
  };

  const onPageChangedHandler = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const onExtractClickedHandler = async () => {
    if (authData?.user.email) {
      try {
        await postJob.mutateAsync({
          type: 'abuseReportersReport',
          params: JSON.stringify({
            email: authData?.user.email,
            userId: userId,
          }),
        });

        presentDialog({
          headerText: '',
          content: (
            <ExtractReportDialog requestName="Abuse Reporters" onOkayClick={() => dismissDialog()} />
          ),
          enableBackdropDismiss: true,
          hideClose: true,
        });
      } catch (error) {
        console.log({error});
        presentToast({
          message: 'Request failed',
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  const flagConversation = usePutFlagConversation();
  const unFlagConversation = usePutUnflagConversation();
  const tableRowClickHandler = (rowData: TableData) => {
    const params = `?reporter=${rowData.userId}`
    history.push(
      RoutePath.ADMIN_CHAT_ROOM.replace(':id', rowData.conversationId) + params
    );
  };

  const onFlagConversation = async (
    rowData: TableData
  ) => {
    try {
      if (rowData.isFlagged)
        await unFlagConversation.mutateAsync(rowData.conversationId);
      else
        await flagConversation.mutateAsync(rowData.conversationId);

      presentToast({
        message: `${rowData.isFlagged ? 'Removed flag' : 'Flagged'} successfully.`,
        variant: 'success',
        position: 'bottom',
      });

    } catch (e) {
      console.log(e);
      presentToast({
        message: `Unexpected error was occurred.`,
        variant: 'error',
        position: 'bottom',
      });
    }
  };

  const manualRenderFn = (columnId: string, rowData: TableData) => {
    if (columnId === 'open')
      return (
        <div className="flex flex-col justify-center items-end">
          <Icon name="chevronRight" color={colorTheme.dark} />
        </div>
    );

    return (
      <div className='flex space-x-2'>
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onFlagConversation(rowData);
          }}>
          <Typography
            label={rowData.isFlagged ? 'Remove flag' : 'Flag'}
            variant="f2"
            color={colorTheme.primary}
            align="left"
          />
        </div>
      </div>
    );
  };

  return (
    <AdminPage>
      <StyledAdminReportersViewPage>
        <div className="w-full flex flex-row">
          <div className="flex-1 flex">
            <div className="flex items-center cursor-pointer" onClick={onBackButtonClickedHandler}>
              <Icon
                name="chevronLeft"
                color={colorTheme.dark}
                size={24}
                type="button" />

              <Typography
                label={`High risk conversations of ${user?.firstName} ${user?.lastName}`}
                variant="f3"
                weight="semibold"
                color={colorTheme.dark}
              />
            </div>
          </div>
          <div>
            <Button
              label="Extract All"
              variant="outlined"
              onClick={onExtractClickedHandler}
              className="btn-search" />
          </div>
        </div>

        <div className="table-container">
          <AdminTable
            columns={columns}
            data={data}
            pageSize={10}
            pageCount={pageCount}
            showDebugView={false}
            onRowClick={tableRowClickHandler}
            onPageChange={onPageChangedHandler}
            manualRenderFn={manualRenderFn} />
        </div>

      </StyledAdminReportersViewPage>
    </AdminPage>
  );
};

export default AdminHighRiskUserViewPage;
