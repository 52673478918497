import {Switch, Route, Redirect} from 'react-router-dom';
import NotFound from '../../views/not-found/NotFound';
import {RoutePath} from '../config/RouteConfig';
import AdminBroadcastPage from '../../pages/admin/broadcast/AdminBroadcastPage';
import AdminControlPage from '../../pages/admin/control/AdminControlPage';
import AdminLandingPage from '../../pages/admin/landing/AdminLandingPage';
import AdminAdsPage from '../../pages/admin/ads/AdminAdsPage';
import AdminReferralsPage from '../../pages/admin/referrals/AdminReferralsPage';
import AdminReferralViewPage from '../../pages/admin/referrals/AdminReferralViewPage';
import AdminReportersViewPage from '../../pages/admin/users/reported/AdminReportersViewPage';
import AdminUsersAllPage from '../../pages/admin/users/all-users/AdminUsersAllPage';
import AdminUsersReportedPage from '../../pages/admin/users/reported/AdminUsersReportedPage';
import AdminUsersBannedPage from '../../pages/admin/users/banned/AdminUsersBannedPage';
import AdminUsersSuspendedPage from '../../pages/admin/users/suspended/AdminUsersSuspendedPage';
import AdminChatPage from '../../pages/admin/chat/AdminChatPage';
import AdminChatRoomPage from '../../pages/admin/chat-room/AdminChatRoomPage';
import ChatProfileView from '../../pages/admin/chat-profile-view/ChatProfileView';
import AdminGeneratedReferralViewPage from '../../pages/admin/referrals/AdminGeneratedReferralViewPage';
import AdminGeneratedReferralsPage from '../../pages/admin/referrals/AdminGeneratedReferralsPage';
import AdminSubscriptionPage from '../../pages/admin/subscription/AdminSubscriptionPage';
import AdminForVerificationUsersPage from '../../pages/admin/users/for-verification/AdminForVerificationUsersPage';
import AdminVerifiedUsersPage from '../../pages/admin/users/verified/AdminVerifiedUsersPage';
import AdminVoucherPage from '../../pages/admin/voucher/AdminVoucherPage';
import AdminEkycVerifiedUsersPage from '../../pages/admin/users/ekyc-verified/AdminEkycVerifiedUsersPage';
import AdminHighRiskUsersPage from '../../pages/admin/users/high-risk/AdminHighRiskUsersPage';
import AdminHighRiskUserViewPage from '../../pages/admin/users/high-risk/AdminHighRiskUserViewPage';

const AdminRouter = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={RoutePath.ADMIN_LANDING}></Redirect>
      </Route>

      <Route exact path={RoutePath.ADMIN}>
        <Redirect to={RoutePath.ADMIN_LANDING}></Redirect>
      </Route>

      <Route exact path={RoutePath.ADMIN_SIGN_IN}>
        <Redirect to={RoutePath.ADMIN_LANDING}></Redirect>
      </Route>

      <Route path={RoutePath.ADMIN_LANDING}>
        <AdminLandingPage />
      </Route>

      <Route path={RoutePath.ADMIN_BROADCAST}>
        <AdminBroadcastPage />
      </Route>

      <Route path={RoutePath.ADMIN_CONTROLS}>
        <AdminControlPage />
      </Route>

      <Route path={RoutePath.ADMIN_CHATS}>
        <AdminChatPage />
      </Route>

      <Route path={RoutePath.ADMIN_CHAT_ROOM}>
        <AdminChatRoomPage />
      </Route>

      <Route path={RoutePath.ADMIN_CHAT_PROFILE}>
        <ChatProfileView />
      </Route>

      <Route path={RoutePath.ADMIN_ADS}>
        <AdminAdsPage />
      </Route>

      <Route path={RoutePath.ADMIN_REFERRALS_VIEW}>
        <AdminReferralViewPage />
      </Route>

      <Route path={RoutePath.ADMIN_REPORT_VIEW}>
        <AdminReportersViewPage />
      </Route>

      <Route path={RoutePath.ADMIN_GENERATED_REFERRALS_VIEW}>
        <AdminGeneratedReferralViewPage />
      </Route>

      <Route path={RoutePath.ADMIN_REFERRALS_BY_USER}>
        <AdminReferralsPage />
      </Route>

      <Route path={RoutePath.ADMIN_GENERATED_REFERRALS}>
        <AdminGeneratedReferralsPage />
      </Route>

      <Route path={RoutePath.ADMIN_USERS_ALL}>
        <AdminUsersAllPage />
      </Route>

      <Route path={RoutePath.ADMIN_USERS_REPORTED}>
        <AdminUsersReportedPage />
      </Route>

      <Route path={RoutePath.ADMIN_USERS_BANNED}>
        <AdminUsersBannedPage />
      </Route>

      <Route path={RoutePath.ADMIN_USERS_SUSPENDED}>
        <AdminUsersSuspendedPage />
      </Route>

      <Route path={RoutePath.ADMIN_SUBSCRIPTION}>
        <AdminSubscriptionPage />
      </Route>

      <Route path={RoutePath.ADMIN_VOUCHER}>
        <AdminVoucherPage />
      </Route>

      <Route path={RoutePath.ADMIN_USERS_FOR_VERIFICATION}>
        <AdminForVerificationUsersPage />
      </Route>

      <Route path={RoutePath.ADMIN_USERS_VERIFIED}>
        <AdminVerifiedUsersPage />
      </Route>

      <Route path={RoutePath.ADMIN_USERS_EKYC_VERIFIED}>
        <AdminEkycVerifiedUsersPage />
      </Route>

      <Route path={RoutePath.ADMIN_HIGH_RISK_USERS}>
        <AdminHighRiskUsersPage />
      </Route>

      <Route path={RoutePath.ADMIN_HIGH_RISK_USER}>
        <AdminHighRiskUserViewPage />
      </Route>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default AdminRouter;
