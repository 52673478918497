import {useEffect, useState} from 'react';
import {QuantityInput, Toggle, Typography} from '../../../../core/components';
import {StyledWrapper} from './styled';
import {colorTheme} from '../../../../core/configs';
import {usePutAdminControl} from '../../../server/react-query';
import {useToast} from '../../../../core/components/toast';
import {
  AdminControl,
  AdminControlReqBody,
  AdminControlType,
  QuantityContent,
} from '../../../server/types/admin-control.types';
import Divider from '../../../../core/components/divider/Divider';
import { KazamException } from '../../../server/types';

interface SalaryLimitsViewProps {
  data: AdminControl[];
}

const SalarySettingsItem = ({
  item,
  label,
  description,
  inputLabel,
  min
}: {
  item?: AdminControl,
  label: string,
  description: string
  inputLabel: string,
  min?: number
}) => {
  const {presentToast} = useToast();

  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [limit, setLimit] = useState<QuantityContent>();
  const [error, setError] = useState<string>();
  const [control, setControl] = useState<AdminControl | undefined>()

  const putAdminControl = usePutAdminControl();

  useEffect(() => {
    if (item) {
      setControl(item)
      setIsEnabled(item?.enabled)
      if (item.content) {
        var limit = JSON.parse(
          item?.content.toLowerCase(),
        ) as QuantityContent;
        setLimit(limit);
      }
    }
  }, [item])

  useEffect(() => {
    if (control) {
      if (control.content) {
        var limit = JSON.parse(
          control?.content.toLowerCase(),
        ) as QuantityContent;
        setLimit(limit);
      }
    }
  }, [control])

  const onSave = (value: QuantityContent) => {
    if (control) {
      const data: AdminControlReqBody = {
        type: control.type,
        enabled: isEnabled,
        content: JSON.stringify(value)
      };
      
      onPutAdminControl(data);
    }
  };

  const handleToggle = (toggled: boolean) => {
    setIsEnabled(toggled)    
    if (control) {
      const data: AdminControlReqBody = {
        type: control.type,
        enabled: toggled,
        content: control.content,
      };
      
      onPutAdminControl(data);
    }
  }

  const onPutAdminControl = async (data: AdminControlReqBody) => {
    try {
      const res = await putAdminControl.mutateAsync(data);
      setControl(res)
      setError("");
      presentToast({
        message: `Successfully saved.`,
        variant: 'success',
        position: 'bottom',
      });
    } catch (e: any) {
      console.log(e);
      const exception = e.data as KazamException;

      if (exception) {
        if (e.status
          && e.status === 403
          && exception.errorCode === 'SalaryLimitError') {
          setError(exception.error);
        }

        presentToast({
          message: exception.error,
          variant: 'error',
          position: 'bottom',
        });
      } else {
        presentToast({
          message: "Failed to save.",
          variant: 'error',
          position: 'bottom',
        });
      }
    }
  };

  return (
    <>
      <div className="pt-3 flex flex-row justify-between items-center">
        <div>
          <Typography
            label={label}
            variant="f3"
            color={isEnabled ? colorTheme.dark : colorTheme.lightGray}
          />
          <Typography
            label={description}
            variant="f1"
            color={
              isEnabled ? colorTheme.darkFaded : colorTheme.lightGray
            }
          />
        </div>
        <div>
          <Toggle value={isEnabled} onToggle={handleToggle} />
        </div>
      </div>

      {isEnabled && (
        <>
          <div className="pt-3">
            <div className="pt-3">
              <QuantityInput
                min={min}
                label={inputLabel}
                quantity={limit}
                onSubmit={onSave}
                isLoading={putAdminControl.isLoading}
                error={error}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
  
}

const SalaryLimitsView = ({data}: SalaryLimitsViewProps) => {
  const getAdminControl = (type: AdminControlType) => {
    return data.find(d => d.type === type);
  }

  return (
    <StyledWrapper>
      <Typography
        label="SALARY LIMITS SETTINGS"
        variant="f1"
        weight="semibold"
        color={colorTheme.black}
      />
      
      <SalarySettingsItem
        item={getAdminControl('salaryLowerLimit')}
        label="Salary lower limit"
        description="Set the lower limit users can set their salary or salary expectation."
        inputLabel='Min Salary'
      />

      <Divider />

      <SalarySettingsItem
        item={getAdminControl('salaryUpperLimit')}
        label="Salary upper limit"
        description="Set the upper limit users can set their salary or salary expectation."
        inputLabel='Max Salary'
      />

      <SalarySettingsItem
        item={getAdminControl('salaryThresholdMultiplier')}
        label="Salary threshold multiplier"
        description="Set the threshhold multiplier for the matching."
        inputLabel='Value'
        min={1}
      />
      
    </StyledWrapper>
  );
};

export default SalaryLimitsView;
