import {
  useMutation,
  useQueryClient,
} from 'react-query';
import {API, ApiEndpointsEnum} from '../api';

const postNotifyTier3ViaSms = () => {
  return API.post(ApiEndpointsEnum.POST_NOTIFY_TIER3_SMS);
};

const usePostNotifyTier3ViaSms = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['post-notify-tier3-sms'],
    () => postNotifyTier3ViaSms(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['post-notify-tier3-sms']);
      },
    },
  );
};

export {
  usePostNotifyTier3ViaSms
};
