import {isMobile} from 'react-device-detect';
import styled, {css} from 'styled-components';
import {colorTheme} from '../../../../core/configs';

export const StyledWrapper = styled.div`
  position: fixed;
  padding: 12px 16px;
  border-top: 1px solid ${colorTheme.lightGray};
  background: white;
  bottom: 0;
  z-index: 1;

  ${isMobile
    ? css`
        left: 0;
        right: 0;
      `
    : css`
        left: 300px;
        right: 300px;
      `}
`;

export const StyledDisclaimer = styled.div`
  background: #aabdd5;
  padding: 0 8px;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;

  & .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledRiskyWarning = styled.div`
  background: #F2F2F2;
  padding: 4px 8px;
  position: absolute;
  bottom: 62px;
  left: 0;
  right: 0;

  & .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .description {
    color: ${colorTheme.darkFaded};
    font-size: 0.65rem;
    line-height: 0.964rem;
    gap: 2px;
  }

  & .learn-more {
    color: ${colorTheme.primary}
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  border-radius: 4px;
  height: 16px;
  max-height: 100%;

  resize: none;

  color: ${colorTheme.dark};

  font-size: 11.2px;
  line-height: 16.8px;

  padding: 0;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${colorTheme.lightGray};
  }
`;
